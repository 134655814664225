module.exports = {
    //  删除<vab-icon icon="delete-bin-line" />
    // 导出<vab-icon icon="download-cloud-2-line" />
    // 下载<vab-icon icon="folder-download-line" />
    // 重置<vab-icon icon="refresh-line" />
    // 保存<vab-icon icon="save-line" />
    // 新增<vab-icon icon="chat-new-line" />
    // 导入<vab-icon icon="file-excel-line" />
    // 搜索<vab-icon icon="search-line" />
    // 日历<vab-icon icon="calendar-2-line" />
    // 上架<vab-icon icon="add-circle-line" />
    // 下架<vab-icon icon="indeterminate-circle-line" />
    // 入库<vab-icon icon="login-box-line" />
    // 批量<vab-icon icon="menu-add-fill" />


    // 首页<vab-icon icon="home-2-line" />
    // 客户中心<vab-icon icon="contacts-line" />
    // 商品中心<vab-icon icon="shopping-basket-2-line" />
    // 采购中心<vab-icon icon="shopping-cart-line" />
    // 核心系统<vab-icon icon="home-gear-line" />
    // 新增服务<vab-icon icon="service-line" />
    // 系统功能<vab-icon icon="home-wifi-line" />
}