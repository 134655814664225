<template>
  <el-dialog
    append-to-body
    class="common-dialog"
    :close-on-click-modal="false"
    height="10vh"
    max-height="680"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <div v-if="dialogFormVisible">
      <el-form
        ref="form"
        class="activitiesForm"
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="标题:" prop="title">
          <el-input
            v-if="pageState != 'view'"
            v-model.trim="form.title"
            class="width200"
            clearable
            maxlength="50"
            placeholder="请输入标题"
            show-word-limit
          />
          <span v-else>{{ form.title }}</span>
        </el-form-item>

        <el-form-item label="内容:" prop="content">
          <el-input
            v-if="pageState != 'view'"
            v-model.trim="form.content"
            class="width200"
            clearable
            placeholder="请输入内容"
            show-word-limit
          />
          <span v-else>{{ form.content }}</span>
        </el-form-item>

        <el-form-item label="类型:" prop="type">
          <el-select
            v-if="pageState != 'view'"
            v-model.trim="form.type"
            class="width200"
            clearable
            placeholder="请选择"
            show-word-limit
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <span v-else>{{ form.typeName }}</span>
        </el-form-item>

        <el-form-item label="图片:" prop="home_image">
          <nxp-upload
            v-bind="$attrs"
            ref="nxpUpload"
            :accessories="activeMainImg.accessories"
            :page-state="pageState"
            :upload-item="activeMainImg"
            @getUploadList="getUploadList"
          />
        </el-form-item>

        <el-form-item label="详情链接:" prop="">
          <el-input
            v-if="pageState != 'view'"
            v-model.trim="form.linkUrl"
            class="width200"
            clearable
            placeholder="请输入详情链接"
            show-word-limit
          />
          <span v-else>{{ form.linkUrl }}</span>
        </el-form-item>
        <el-form-item v-if="form.type == 'ghpd'" label="标记:" prop="">
          <el-input
            v-if="pageState != 'view'"
            v-model.trim="form.tip"
            class="width200"
            clearable
            placeholder="请输入标记"
            show-word-limit
          />
          <span v-else>{{ form.tip }}</span>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-button v-if="pageState != 'view'" type="primary" @click="save">
        确 定
      </el-button>
      <el-button @click="close">
        {{ pageState == "add" ? "取 消" : "关 闭" }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  saveDataItem,
  queryDataDetail,
  delDataItem,
} from "@/project/eztree/baseApi/HomeApi";

export default {
  name: "AddEdit",
  components: {},
  props: {
    selectOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      pageObj: {
        pageType: "components",
        selectType: "checkBox",
      },
      jsonValue: [],
      form: {
        id: "",
        title: "",
        content: "",
        home_image: "",
        type: null,
        linkUrl: "",
        tip: "",
      },
      checkValid: false,
      //   设置开始时间应不允许大于结束时间
      defaultTime: new Date(2000, 1, 1, 12, 0, 0), // '12:00:00'
      validityTermStart: "",
      validityTermEnd: "",
      oneDayMsec: 1 * 24 * 3600 * 1000, //一天的毫秒数
      pickerOptionsStart: {
        disabledDate: (time) => {
          var time1 = new Date(this.form.endTime).getTime(); // 开始日期时间戳
          let a = time.getTime() <= Date.now() - 8.64e7;
          return time1 ? a || time.getTime() > time1 : a;
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          var time1 = new Date(this.form.beginTime).getTime(); // 开始日期时间戳
          let a = time.getTime() <= Date.now() - 8.64e7;
          return time1 ? a || time.getTime() <= time1 - this.oneDayMsec : a;
        },
      },
      rules: {
        title: [{ required: true, trigger: "blur", message: "请输入标题" }],
        beginTime: [
          {
            validator: function (rule, value, callback) {
              // return checkInterface('beginTime', rule, value, callback)
            },
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        endTime: [
          {
            validator: function (rule, value, callback) {
              // return checkInterface('endTime', rule, value, callback)
            },
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        region: [
          {
            validator: function (rule, value, callback) {
              return callback();
            },
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        content: [{ required: true, trigger: "blur", message: "请输内容" }],
        type: [{ required: true, trigger: "blur", message: "请输内容" }],
        home_image: [
          {
            validator: function (rule, value, callback) {
              return callback();
            },
            required: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      pageState: "add",
      title: "",
      dialogFormVisible: false,
      activeMainImg: {
        type: "uploadFile",
        label: "图片",
        model: [],
        prop: "home_image",
        rules: {
          required: true,
          files: true,
        },
        accessories: {
          dataType: "string",
          disabled: false,
          multiple: false,
          limit: 1,
          accept: ".jpg,.png",
          listType: "picture-card",
          fileParam: {},
        },
      },
    };
  },
  watch: {},
  created() {},
  methods: {
    getUploadList(uploadItem, result) {
      if (uploadItem.accessories.accept == ".xlsx") {
        this.form[uploadItem.prop] = result[0].data;
      }
      if (uploadItem.accessories.accept == ".jpg,.png") {
        this.form[uploadItem.prop] = result[0].url;
      }
      console.log("getUploadList", uploadItem, result, this.form);
    },
    // 打开弹框
    showEdit(row, action) {
      let params = Object.assign({});
      params.id = row.id;
      if (action == "delete") {
        this.$confirm("确认删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delDataItem(params).then(({ code, data }) => {
              if (code == "200") {
                this.$baseMessage("删除成功", "success", "vab-hey-message-success");
                this.$emit("fetch-data");
              }
            });
          })
          .catch(() => {});
        return false;
      }
      this.pageState = action;
      this.title =
        action == "add"
          ? "创建"
          : action == "edit"
          ? "编辑"
          : action == "view"
          ? "查看"
          : "";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        if (action != "add") {
          queryDataDetail(params).then(({ code, data }) => {
            const { id, title, content, home_image, type, linkUrl, tip } = data;
            this.form = Object.assign(
              {},
              { id, title, content, home_image, type, linkUrl, tip }
            );
            this.selectOptions.forEach((item) => {
              if (item.value == type) {
                this.form.typeName = item.label;
              }
            });
            console.log(this.selectOptions, type, this.form.typeName);
            this.$refs.nxpUpload.callBackItemImg(JSON.stringify([this.form.home_image]));
          });
        }
      });
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.nxpUpload.validateSubmit();
      this.$refs["form"].validate((valid) => {
        console.log(this.$refs.nxpUpload.checkValid, valid, this.form);
        Promise.all([this.$refs.nxpUpload.checkValid, valid])
          .then((values) => {
            console.log("values", values);
            //都调成功以后执行的操作
            if (values.every((item) => !!item)) {
              console.log("params", this.form);
              saveDataItem(this.form).then(({ code, data }) => {
                if (code == "200") {
                  this.$baseMessage("操作成功", "success", "vab-hey-message-success");
                  this.$emit("fetch-data");
                  this.close();
                }
              });
            } else {
              this.$baseMessage("必填项未填写", "error", "vab-hey-message-error");
            }
          })
          .catch((err) => {
            // 抛出错误信息
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
