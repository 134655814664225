var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c("nxp-search", {
        attrs: { "config-list": _vm.searchConfigList },
        on: { clickSearch: _vm.clickSearch, resetForm: _vm.resetForm },
      }),
      _c("vab-query-form", [
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c(
              "el-button",
              {
                staticClass: "leftBtn",
                attrs: { icon: "el-icon-plus", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.handleEdit("", "add")
                  },
                },
              },
              [_vm._v(" 创建 ")]
            ),
          ],
          1
        ),
      ]),
      _c("select-table", {
        attrs: {
          accessories: _vm.nxpTabelAccessories,
          "only-key-id": "id",
          "table-columns": _vm.tableColumns,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "w-theight": 320,
        },
        on: { fetchData: _vm.fetchData },
      }),
      _c("addEdit", {
        ref: "edit",
        attrs: { "select-options": _vm.selectOptions },
        on: { "fetch-data": _vm.clickSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }