import request from '@/utils/request'
// 首页列表
export function queryDataList (data) {
  return request({
    url: '/admin/home-list',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: '/home-save',
    method: 'post',
    data,
  })
}
// 详情
export function queryDataDetail (data) {
  return request({
    url: '/home/info',
    method: 'post',
    data,
  })
}
// 删除
export function delDataItem (data) {
  return request({
    url: '/home/del',
    method: 'post',
    data,
  })
}

