var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "common-dialog",
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        height: "10vh",
        "max-height": "680",
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.pageState != "view"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v(" 确 定 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(
                  " " + _vm._s(_vm.pageState == "add" ? "取 消" : "关 闭") + " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.dialogFormVisible
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "activitiesForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题:", prop: "title" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              clearable: "",
                              maxlength: "50",
                              placeholder: "请输入标题",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "title",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.title",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.title))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容:", prop: "content" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入内容",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.content,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "content",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.content",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.content))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型:", prop: "type" } },
                    [
                      _vm.pageState != "view"
                        ? _c(
                            "el-select",
                            {
                              staticClass: "width200",
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "type",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.type",
                              },
                            },
                            _vm._l(_vm.selectOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.form.typeName))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片:", prop: "home_image" } },
                    [
                      _c(
                        "nxp-upload",
                        _vm._b(
                          {
                            ref: "nxpUpload",
                            attrs: {
                              accessories: _vm.activeMainImg.accessories,
                              "page-state": _vm.pageState,
                              "upload-item": _vm.activeMainImg,
                            },
                            on: { getUploadList: _vm.getUploadList },
                          },
                          "nxp-upload",
                          _vm.$attrs,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详情链接:", prop: "" } },
                    [
                      _vm.pageState != "view"
                        ? _c("el-input", {
                            staticClass: "width200",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入详情链接",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.linkUrl,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "linkUrl",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.linkUrl",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.form.linkUrl))]),
                    ],
                    1
                  ),
                  _vm.form.type == "ghpd"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标记:", prop: "" } },
                        [
                          _vm.pageState != "view"
                            ? _c("el-input", {
                                staticClass: "width200",
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入标记",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.tip,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "tip",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.tip",
                                },
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.tip))]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }