import request from '@/utils/request'
// 首页列表
export function queryDataList (data) {
  return request({
    url: '/admin/qr-list',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: '/admin/qr-save',
    method: 'post',
    data,
  })
}
// 删除
export function delDataItem (data) {
  return request({
    url: '/admin/qr-del',
    method: 'post',
    data,
  })
}

